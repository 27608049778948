/* global Component, wrapPost, wrapGet */
class PersonGenericComponent extends Component {

    static name() {
        return "PersonGenericComponent";
    }

    static componentName() {
        return "PersonGenericComponent";
    }

    getProps() {
        return {
            "useLastName2": {
                type: Boolean,
                default: false
            },
            'personObj': {
                type: Object,
                required: false
            },
            'confirmPassword': {
                type: Boolean,
                default: false
            }
        };
    }

    data() {
        return {
            _response: null,
            alertError: [],
            alertErrorAddress: [],
            alertParams: [],
            getTaxNrLabel: 'CUIT',
            personEnabled: false,
            AddCompanyAddressFlag: false,
            TermsAndConditions: false,
            selectedCountry: "",
            phone: "",
            countryList: {},
            person: {
                Code: null,
                WebUserFlag: true,
                ContactType: null,
                Sex: null,
                Name: "",
                LastName: "",
                LastName2: "",
                Phone: "",
                Email: "",
                JobPosition: null,
                WebUser: "",
                WebPassword: "",
                WebPassword2: null,
                WebAccessGroup: "",
                CountryCode: null,
                Country: "",
                TaxNr: "",
                Customer: "",
            },
            customer: {
                Code: null,
                Name: "",
                TaxRegNr: "",
                TaxRegType: null,
                TaxReg2Type: null,
                TaxRegNr1: null,
                TaxReg2Province: null,
                TaxRegSat: null
            },
            customerAddress: {
                Name: '',
                normalizeAddress: null,
                Address: '',
                Country: '',
                CountryName: null,
                ProvinceCode: '',
                Province: null,
                Locality: '',
                LocalityCode: null,
                ZipCode: '',
                City: '',
                Street: '',
                StreetNumber: '',
                Floor: '',
                Apartment: "",
                Latitude: null,
                Longitude: null,
                normalize: false,
                CustomLocation: false
            },
            readyNormalize: false
        };
    }

    getWatch() {
        return {
            "addressRequired": function (newValue, oldVal) {
                if (newValue) {
                    this.AddCompanyAddressFlag = newValue;
                }
            },
            "isResponInsp": function (newVal, oldVal) {
                this.AddCompanyAddressFlag = newVal;
            },
            "person.Email": function (newValue, oldValue) {
                if (oldValue === this.person.WebUser)
                    this.person.WebUser = newValue;
            },
            "person.Phone": function (newValue, oldValue) {
                if (this.$store.getters.getSetting.PhoneValidator)
                    if (newValue && oldValue) {
                        let newValueReplaced = newValue.replace(/ /g, "").replace(/\D/g, "");
                        if (this.phonePattern.test('+' + newValueReplaced))
                            this.person.Phone = "+" + newValueReplaced;
                        else
                            this.person.Phone = newValueReplaced;
                    }
            },
            'customerAddress.ProvinceCode': function (val, oldval) {
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.City': function (val, oldval) {
                if (this.customer.City)
                    this.person.City;
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.Locality': function (val, oldval) {
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.Street': function (val, oldval) {
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.StreetNumber': function (val, oldval) {
                this.buildAddress();
                this.customerAddress.normalize = true;
            },
            'customerAddress.Floor': function () {
                this.buildAddress();
            },
            'customerAddress.Apartment': function () {
                this.buildAddress();
            },
            customerExtraFields: function () {
                this.loadCustomerDefault();
            }
        };
    }

    created() {
        return function () {
            if (this.personObj)
                this.person = this.personObj;
        };
    }

    mounted() {
        return async function () {
            window.$PersonGenericComponent = this;
            this.subscribeEvent("address-relocation", (customEvent) => {
                window.setTimeout(() => {
                    this.triggerSaveAction(customEvent);
                }, 300);
            });
            this.subscribeEvent("address-relocation-cancel", (customEvent) => {
                window.setTimeout(() => {
                    this.triggerSaveAction(customEvent);
                }, 300);
            });
            this.AddCompanyAddressFlag = this.addressRequired;

            this.clearForm.bind(this);
            this.registerNewPerson.bind(this);
            this.$store.dispatch('updateSelectLinkto', {
                table: "modalPerson_Country",
                value: this.$store.getters.getOurSettingsCountry
            });

            this.person.Country = this.$store.getters.getOurSettingsCountry;
            this.customerAddress.Country = this.$store.getters.getOurSettingsCountry;
            this.subscribeEvent('person-reset', this.clearForm);
            this.subscribeEvent('person-save', this.triggerSaveAction);
            this.triggerTaxNrType();
            $('#customerTaxRegType').tooltip();
            $('#WebPassword').tooltip();
            this.loadCustomerDefault();
            this.countryList = await this.getCountryList();
        };
    }

    getComputed() {
        return {
            labeCustomerTaxRegType: function () {
                return this.$store.state.labeCustomerTaxRegType || 'Inscription';
            },
            requiredBirthDate: function () {
                return this.$store.getters.getSetting.RequiredBirthDateInRegister || false;
            },
            showLastName2: function () {
                return this.useLastName2 || ['mx'].indexOf(this.$store.state.OurSettingsCountry) !== -1;
            },
            addressRequired: function () {
                return this.$store.getters.AddressRequiredInRegister || this.$store.state.addressSetting.DontAllowNonExistentCity;
            },
            showCountry: function () {
                return this.$store.getters.showCountryAddress;
            },
            customerExtraFields: function () {
                return this.$store.getters.getCustomerExtraFields(this.customer.TaxRegType);
            },
            hideTaxRegType: function () {
                return this.customerExtraFields ? this.customerExtraFields.HideTaxRegType : false;
            },
            customerHideFields: function () {
                let map = this.$store.getters.getCustomerExtraFields(this.customer.TaxRegType);
                if (!map || !map.HideFields) {
                    return [];
                }
                return map.HideFields;
            },
            customClass: function () {
                return {};
            },
            stringPattern: async function () {
                let validators = await wrapGet(`/${this.$store.getters.getSetting.PathURL}/api/getValidators`);
                if (this.customerAddress.Country) {
                    let country = await wrapGet(`/${this.$store.getters.getSetting.PathURL}/api/getCountryName/` + this.customerAddress.Country);
                    let countryName = country ? country.data.CountryName : null;
                    if (countryName) {
                        let exp = validators.data[countryName];
                        return exp;
                    }
                }
            },
            phonePattern: function () {
                if (this.$store.getters.getSetting.PhoneValidator) {
                    return new RegExp(this.stringPattern);
                }
                return new RegExp(/\w*/);
            },
            provinceFilter() {
                //console.log('Reload Province Filter');
                let filter = {};
                if (this.customerAddress.Country)
                    filter['Country'] = this.customerAddress.Country;
                return filter;
            },
            cityFilter() {
                let filter = {};
                if (this.customerAddress.ProvinceCode)
                    filter['Province'] = this.customerAddress.ProvinceCode;
                return filter;
            },
            localityFilter() {
                let filter = {};
                if (this.customerAddress.ProvinceCode)
                    filter['ProvinceCode'] = this.customerAddress.ProvinceCode;
                return null;
            },
            zipCodeFilter() {
                let filter = {};
                if (this.customerAddress.LocalityCode)
                    filter['LocalityCode'] = this.customerAddress.LocalityCode;
                return null;
            },
            disableZipCode() {
                return this.$store.getters.getSetting.DisableZipCode;
            },
            isConsFinal: function () {
                if (this.customer.TaxRegType != null) {
                    return this.customer.TaxRegType === "1";
                }
                return false;
            },
            isResponInsp: function () {
                if (this.addressRequired)
                    return true;
                if (this.customer.TaxRegType != null) {
                    return this.customer.TaxRegType != "1";
                }
                return false;
            },
            inInsertMode: function () {
                if (this.personObj)
                    return false;
                return true;
            },
            getCustomerTaxRegTypes() {
                let settingsType = this.$store.getters.getSetting.CustomerIDTypes;
                if (settingsType) {
                    let codes = settingsType.split(",");
                    let filtertypes = [];
                    for (let regType of this.$store.getters.getCustomerTaxReg)
                        if (codes.indexOf(regType.value.toString()) !== -1)
                            filtertypes.push(regType);
                    return filtertypes;
                }
                return this.$store.getters.getCustomerTaxReg;
            }
        };
    }

    getPhoneNumber(event) {
        this.person.Phone = this.selectedCountry + event.target.value; 
    }

    async getCountryList() {
        const regexCodes = /\+\d+/g;
        let countryCodes = {};
        let validators = await wrapGet(`/${this.$store.getters.getSetting.PathURL}/api/getValidators`);
        for (const [country, regexStr] of Object.entries(validators.data)) {
            const match = regexStr.match(regexCodes);
            countryCodes[country] = match ? match[0] : "Not found";
        }
        return countryCodes;
    }

    loadCustomerDefault() {
        let newValue = this.customerExtraFields;
        if (newValue && newValue.ExtraFields) {
            for (let fieldName of Object.keys(newValue.ExtraFields)) {
                if (fieldName == 'DocType') continue;
                if (newValue.ExtraFields[fieldName] && newValue.ExtraFields[fieldName].default)
                    this.customerPasteField(newValue.ExtraFields[fieldName].default, fieldName);
            }
        }

        let taxTypes = this.getCustomerTaxRegTypes;
        if (taxTypes.length == 1) {
            this.customer.TaxRegType = taxTypes[0].value;
        }
    }

    triggerTaxNrType() {
        let label = "CUIT";
        if (this.customer.TaxRegType === "1") {
            label = "DNI";
        }
        this.getTaxNrLabel = label;
        if (this.labeCustomerTaxRegType !== 'Inscription')
            this.customer[this.labeCustomerTaxRegType] = this.customer.TaxRegType;
    }

    getRegisterData(address) {
        return {
            personData: this.person,
            customerData: this.customer,
            createCustomer: true,
            customerAddress: address,
            addAddressFlag: this.AddCompanyAddressFlag,
        };
    }

    triggerSaveAction(eventAddress) {
        this.alertError = [];
        this.alertErrorAddress = [];

        this.checkPersonFields();
        if (this.AddCompanyAddressFlag) {
            this.checkAddressFields();
        }

        if (this.alertError.length > 0 || this.alertErrorAddress.length > 0) {
            if (this.alertError.length > 0)
                window.scrollTo(0, 0);
            return;
        }

        let address = this.customerAddress;
        if (eventAddress)
            address = eventAddress;

        let openMap = address.normalize;
        if (openMap) {
            this.emitEvent("addressNeedConfirmations", address);
        } else {
            let data = this.getRegisterData(address);

            window.scrollTo(0, 0);
            if (!this.$store.state.screenState)
                this.$store.dispatch('toogleLockScreen', this.tr('Processing'));

            wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/newPerson`, data).then((response) => {
                if (response) {
                    this._response = response;
                    if (this._response.data.success) {
                        this.person.Code = this._response.data.personCode;
                        this.alertError = [];
                        this.clearForm();
                        this.closeModal();
                        if (!this.isLogged)
                            this.emitEvent('registerSuccess');
                    } else {
                        if (Array.isArray(this._response.data.errors)) {
                            if (typeof (this._response.data.errors[0]) === 'string')
                                this.alertError = this._response.data.errors[0];
                            else if (this._response.data.errors[0].code)
                                this.alertError = [this._response.data.errors[0].code, this._response.data.errors[0].params.FieldName];
                        } else
                            this.alertError = this._response.data.errors;
                        window.scrollTo(0, 0);
                    }
                } else {
                    this.alertError = "Error Try register a new Client";
                }
                if (this.$store.state.screenState)
                    this.$store.dispatch('toogleLockScreen');
            });
        }
    }

    confirmAddressTrigger(address) {
        if (address.Address === this.customerAddress.Address)
            this.customerAddress = address;
    }

    buildAddress() {
        let newAddress = '';
        if (this.customerAddress.Street) {
            newAddress = this.customerAddress.Street + " ";
        }
        if (this.customerAddress.StreetNumber) {
            newAddress += this.customerAddress.StreetNumber + " ";
        }
        if (this.customerAddress.Floor) {
            newAddress += this.customerAddress.Floor + " ";
        }
        if (this.customerAddress.Apartment)
            newAddress += this.customerAddress.Apartment + " ";

        this.customerAddress.Address = newAddress;
    }

    clearForm(force = false) {
        this.alertError = [];
        this.alertErrorAddress = [];
        if (this.inInsertMode && force)
            return;
        for (let dataEntry of Object.keys(this.person))
            this.person[dataEntry] = null;
        for (let dataEntry of Object.keys(this.customer))
            this.customer[dataEntry] = null;

        this.$store.dispatch('clearSelectsLinkto');
        this.updateSelectValues();
    }

    customerPasteField(event, target) {
        let fieldValue = event.target ? event.target.value : event;
        this.customer[target] = fieldValue;
    }

    addressPasteField(data, sourceName, target) {
        if (data && Array.isArray(data.linkToData) && data.linkToData.length > 0)
            this.customerAddress[target] = data.linkToData[0][sourceName];
        else
            this.customerAddress[target] = null;
    }

    updateSelectValues() {
        this.customerAddress.Country = this.$store.getters.getSelectLinkToValue('modalPerson_Country');
    }

    checkAddressFields() {
        if (!this.customerAddress.Street)
            return this.alertErrorAddress = ['Please fill the field {{$1}}', "Street"];
        if (!this.customerAddress.StreetNumber)
            return this.alertErrorAddress = ['Please fill the field {{$1}}', "Number"];
        if (!this.customerAddress.ProvinceCode)
            return this.alertErrorAddress = ['Please fill the field {{$1}}', "Province"];
        if (!this.customerAddress.City)
            return this.alertErrorAddress = ['Please fill the field {{$1}}', "City"];
        if (!this.customerAddress.ZipCode && !this.disableZipCode)
            return this.alertErrorAddress = ['Please fill the field {{$1}}', "ZipCode"];
    }

    checkPersonFields() {
        if (!this.person.Name) {
            this.alertError = ['Please fill the field {{$1}}', "Name"];
        }
        if (!this.person.LastName) {
            this.alertError = ['Please fill the field {{$1}}', "Last Name"];
        }
        if (!this.person.Phone) {
            this.alertError = ['Please fill the field {{$1}}', "Phone"];
        }
        if (!this.person.Email) {
            this.alertError = ['Please fill the field {{$1}}', "Email"];
        }
        if (!this.person.WebUser) {
            this.alertError = ['Please fill the field {{$1}}', "User"];
        }
        if (!this.person.WebPassword) {
            this.alertError = ['Please fill the field {{$1}}', "Password"];
        }

        if (this.useLastName2 && this.person.LastName2 === "")
            this.alertError = ['Please fill the field {{$1}}', "Mothers Maiden Name"];

        const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!mailReg.test(this.person.Email))
            this.alertError = ['FORMATVALUEERR', "Email"];

        if (this.customer.TaxRegNr.length === 0)
            this.alertError = ['Please fill the field {{$1}}', this.getTaxNrLabel];
        else {
            if (this.$store.state.OurSettingsCountry === "ar" && this.customer.TaxRegType === 0) {
                if (this.customer.TaxRegNr.length === 11) {
                    this.customer.TaxRegNr = `${this.customer.TaxRegNr.slice(0, 2)}-${this.customer.TaxRegNr.slice(2, 10)}-${this.customer.TaxRegNr.slice(10)}`;
                }
                if (!/^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/g.test(this.customer.TaxRegNr))
                    this.alertError = ['FORMATVALUEERR', this.getTaxNrLabel];
            }
        }

        let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        let mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

        if (!strongRegex.test(this.person.WebPassword) && !mediumRegex.test(this.person.WebPassword))
            this.alertError = ["Password must include uppercase, lowercase and number"];
        if (this.person.WebPassword.length < 6)
            this.alertError = ["Password length must be greater than {{$1}}", "5"];

        if (this.confirmPassword)
            if (this.person.WebPassword != this.person.WebPassword2)
                this.alertError = ["Password doesn't match"];

        if (!this.phonePattern.test(this.person.Phone))
            this.alertError = ["FORMATVALUEERR", "Phone"];

        if (!this.TermsAndConditions) {
            this.alertError = ['You must accept Terms and Condition to continue'];
        }
    }

    registerNewPerson() {
        this.checkPersonFields();
        if (this.AddCompanyAddressFlag) {
            this.checkAddressFields();
        }
        if (this.alertError.length === 0 && this.alertErrorAddress.length === 0) {
            this.triggerSaveAction();
        } else
            window.scrollTo(0, 0);
    }

    closeModal() {
        $("#PersonModal").modal('toggle');
    }

    loadTaxNr() {
        wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/getRecordByFilter`, {
            record: 'Customer',
            filter: {
                TaxRegNr: this.TaxRegNr
            }
        })
            .then((response) => {
                // eslint-disable-next-line no-console
                console.log(response);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    processEvent() {
        // eslint-disable-next-line no-console
        console.log("Receive Event From Container");
    }

    getMethods() {
        return {
            getRegisterData: this.getRegisterData,
            loadCustomerDefault: this.loadCustomerDefault,
            triggerTaxNrType: this.triggerTaxNrType,
            triggerSaveAction: this.triggerSaveAction,
            confirmAddressTrigger: this.confirmAddressTrigger,
            buildAddress: this.buildAddress,
            clearForm: this.clearForm,
            customerPasteField: this.customerPasteField,
            addressPasteField: this.addressPasteField,
            updateSelectValues: this.updateSelectValues,
            checkAddressFields: this.checkAddressFields,
            checkPersonFields: this.checkPersonFields,
            registerNewPerson: this.registerNewPerson,
            closeModal: this.closeModal,
            loadTaxNr: this.loadTaxNr,
            processEvent: this.processEvent,
            getCountryList: this.getCountryList,
            getPhoneNumber: this.getPhoneNumber
        };
    }

    getTemplate() {
        return `<form autocomplete="off" class="ecommerce-standard">
                    <div class="col-sm-12" v-if="alertError.length>0">
                        <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" >
                            <strong>{{tr(alertError)}}!</strong>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertError=[]">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="row" :class="customClass">
                        <div class="col-sm-6"  :class="{'d-none':  hideTaxRegType}">
                            <div class="form-group">
                                <label for="customerTaxRegType" class="form-label">{{tr(labeCustomerTaxRegType)}}</label>
                                <select id="customerTaxRegType" v-model="customer.TaxRegType" type="text" class="form-control"  v-on:change.after="triggerTaxNrType"  :disabled="getCustomerTaxRegTypes.length==1">
                                    <option value="null" >{{tr("Select One")}} </option>
                                    <template v-for="regType of getCustomerTaxRegTypes">
                                        <option :value="regType.value" >{{tr(regType.label)}} </option>
                                    </template>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-6"  v-if="customer.TaxRegType==null">    
                            <div class="form-group">
                                <div class="alert alert-info" role="alert">
                                    {{tr('Please select a Tax Type to complete necessary data')}}.
                                </div>
                            </div>
                        </div>
                        <template v-if="customerExtraFields">
                            <template v-for="(def,field) in customerExtraFields.ExtraFields">
                                <div v-if="field !== 'DocType'" class="col-sm-6" :class="{'d-none': def.hidden}">
                                    <proxyInput :def="def" @input="(value)=> customerPasteField(value,field)" :data-field="field"  />
                                </div>
                            </template>
                        </template>  
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="firstname" class="form-label">{{tr('Name')}}</label>
                                <input id="personName" v-model="person.Name" type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-sm-6" v-if="!customerHideFields.includes('LastName')">
                            <div class="form-group">
                                <label for="lastname" class="form-label">{{tr('Last Name')}}</label>
                                <input id="personLastName" v-model="person.LastName" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" >
                            </div>
                        </div>
                        <div class="col-sm-6" v-if="showLastName2">
                            <div class="form-group">
                                <label for="lastname" class="form-label">{{tr('Mothers Maiden Name')}}</label>
                                <input id="personLastName2" v-model="person.LastName2" type="text" class="form-control" aria-autocomplete="none" autocomplete="none" >
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <div style="display: flex; flex-direction: column; gap: 5px;">
                                    <label for="personPhone" class="form-label">{{tr('Phone')}}</label>
                                    <div style="display: flex; gap: 10px; align-items: center;">
                                      <select class="form-control" id="country" v-model="selectedCountry" style="max-width: 80px; min-width: 60px;">
                                        <option v-for="(code, country) in countryList" :key="code" :value="code">
                                          {{ code }} &nbsp;({{ country }})
                                        </option>
                                      </select>
                                      <input class="form-control" style="flex-grow: 1;" id="phone" type="tel" v-model="phone" placeholder="Ingresa tu número" @change="getPhoneNumber"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="personMail" class="form-label">{{tr('Mail')}} ({{tr('Will be used as user')}}) </label>
                                <input id="personMail" v-model="person.Email" type="email" class="form-control" aria-autocomplete="none" autocomplete="none">
                            </div>
                        </div>
                        <div class="col-sm-6" v-if="requiredBirthDate">
                            <div class="form-group">
                                <label for="personBirthDate" class="form-label">{{tr('BirthDate')}}</label>
                                <input id="personBirthDate" v-model="person.BirthDate" type="date" class="form-control" aria-autocomplete="none" autocomplete="none">
                            </div>
                        </div>
                        <div class="col-sm-6 d-none">
                            <div class="form-group">
                                <label for="WebUser" class="form-label">{{tr('User')}}</label>
                                <input id="WebUser" v-model="person.WebUser" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="WebPassword" class="form-label">{{tr('Password')}}</label>
                                <input id="WebPassword" v-model="person.WebPassword" type="password" class="form-control" aria-autocomplete="none" autocomplete="new-password" data-trigger="focus" data-container="body" data-toggle="tooltip" data-placement="top" :title="tr('It must contain at least 6 digits, capital letter, lowercase letter and number')">
                            </div>
                        </div>
                        <div class="col-sm-6" v-if="confirmPassword">
                            <div class="form-group">
                                <label for="WebPassword" class="form-label">{{tr('Confirm Password')}}</label>
                                <input id="WebPassword" v-model="person.WebPassword2"  type="password" class="form-control" aria-autocomplete="none" autocomplete="new-password">
                            </div>
                        </div>
                </div>
                <div class="block-header">
                    <h5 class="text-center">{{tr('ECOMADDRESS')}}</h5>
                </div>
                <hr>
                <template v-if="!isResponInsp && !addressRequired">
                    <div class="custom-control custom-checkbox ml-2" :class="{disabled:isResponInsp}">
                        <input type="checkbox" class="custom-control-input" id="AddCompanyAddressFlag" v-model="AddCompanyAddressFlag" :disabled="isResponInsp">
                        <label class="custom-control-label" for="AddCompanyAddressFlag">{{tr('Do you want to add address now? You can do it later')}}.</label>
                    </div>
                </template>      
                <template v-if="AddCompanyAddressFlag">
                    <div class="col-sm-12" v-if="alertErrorAddress.length>0">
                        <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" >
                            <strong>{{tr(alertErrorAddress)}}!</strong>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertErrorAddress=[]">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="row mt-4">
                            <div class="col-sm-12" >
                                <div class="form-group" >
                                    <label for="selectedAddress" class="form-label">{{tr('Street')}}</label>
                                    <input id="selectedAddressStreet" v-model="customerAddress.Street" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3" >
                                <div class="form-group" >
                                    <label for="selectedAddress" class="form-label">{{tr('Number')}}</label>
                                    <input id="selectedAddressNumber" v-model="customerAddress.StreetNumber" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                </div>
                            </div>
                            <div class="col-12 col-md-3" >
                                <div class="form-group" >
                                    <label for="selectedAddress" class="form-label">{{tr('Floor')}} ({{tr('Optional')}})</label>
                                    <input id="selectedAddressFlat" v-model="customerAddress.Floor" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                </div>
                            </div>
                            <div class="col-12 col-md-6" >
                                <div class="form-group" >
                                    <label for="selectedAddress" class="form-label">{{tr('Apartment')}} ({{tr('Optional')}})</label>
                                    <input id="selectedAddressApartment" v-model="customerAddress.Apartment" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                </div>
                            </div>
                            <div class="col-sm-12 d-none" >
                                <div class="form-group" >
                                    <label for="customerAddress" class="form-label">{{tr('Address')}}</label>
                                    <textarea id="customerAddress" v-model="customerAddress.Address" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                                    </textarea>
                                </div>
                            </div>
                            <div class="col-sm-6" v-if="showCountry">
                                <div class="form-group">
                                    <label for="customerCountry" class="form-label">{{tr('Country')}}</label>
                                    <selectLinkToComponent  v-model="customerAddress.Country" recordKey="modalPerson"  :table="'Country'" :paste="'Code'" :showField="'Name'" :selected="customerAddress.Country"  @update-value-full="(data)=> addressPasteField(data,'Name','CountryName' )"> </selectLinkToComponent>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-label">{{tr("Province")}}</label>
                                    <selectLinkToComponent v-model="customerAddress.ProvinceCode" recordKey="modalPerson" :table="'Province'" :paste="'Code'" :showField="'Name'" :filters="provinceFilter" :selected="customerAddress.ProvinceCode"  @update-value-full="(data)=> addressPasteField(data,'Name','Province' )"> </selectLinkToComponent>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="form-label">{{tr('City')}}</label>
                                    <selectLinkToComponent v-model="customerAddress.City" recordKey="modalPerson"  :table="'City'" :paste="'Code'" :showField="'Name'"  :filters="cityFilter" :selected="customerAddress.City" > </selectLinkToComponent>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label class="form-label">{{tr('Locality')}}</label>
                                <input id="customLocality" v-model="customerAddress.Locality" type="text" class="form-control" aria-autocomplete="none" autocomplete="none">
                            </div>
                            <div class="col-sm-6" v-if="!disableZipCode">
                                <label class="form-label">{{tr('ZipCode')}}</label>
                                <input id="customZipCode" v-model="customerAddress.ZipCode" type="number" class="form-control" aria-autocomplete="none" autocomplete="none">
                            </div>
                    </div>
                </template>
                <div class="custom-control custom-checkbox ml-2">
                    <input type="checkbox" class="custom-control-input" id="TermsAndConditions" v-model="TermsAndConditions">
                    <label class="custom-control-label" for="TermsAndConditions">{{tr('Accept Terms and Conditions')}}.</label>
                </div>
                <hr>
            </form>`;
    }
}

PersonGenericComponent.registerComponent();
